import React from 'react'
import styled from 'styled-components'

const SectionWrapper = styled.div`
  max-width: 1920px;
  margin: auto;
  width: 96vw;
  display: flex;
  flex-wrap: wrap;
  padding: 50px 20px;

  .content-section {
    flex: 1;
    min-width: 300px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center; /* Center content horizontally */
    text-align: center; /* Center text inside description */

    .description {
      color: #1f1f1f;

      p {
        line-height: 1.8;
        font-size: 1rem;
      }
      @media (max-width: 1090px) {
        font-size: 0.9rem;
      }
      p {
        @media (min-width: 1650px) {
          font-size: 1.3rem;
        }
      }
    }

    .form-and-image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 20px; /* Space between form-section and image-container */
      width: auto;
      background-image: linear-gradient(to top, #e3f0ff 0%, #f3e7e9 99%, #e3f0ff 100%);
      border-radius: 42px; /* Border radius for the container */
      padding: 20px; /* Padding for the container */
      box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
      margin-top: 20px; /* Space between description and this container */

      .form-section {
        flex: 1;
        min-width: 300px;
        max-width: 450px;
        color: #102c64;
        background-color: #102c64; /* Keep form-section background as it is */
        background-image: radial-gradient(#ffffff33 1px, #102c64 1px); /* Keep form-section background as it is */
        background-size: 10px 10px;
        padding: 20px;
        border-radius: 10px;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 8px;
          background: linear-gradient(90deg, #34a1d5, #98da5a);
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }

        h3 {
          color: #fff;
          margin-bottom: 20px;
          text-align: center;
          @media (max-width: 1090px) {
            font-size: 1rem;
          }
        }

        button {
          background-color: #34a1d5;
          color: white;
          border: none;
          border-radius: 42px;
          padding: 10px 20px;
          font-size: 1rem;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: #fff;
            color: #111;
          }
        }
      }

      .image-container {
        flex: 1;
        min-width: 300px;
        max-width: 40%; /* Reduce container width to half */
        text-align: center;
        border-radius: 10px; /* Border radius for the container */
        /* @media (max-width: 1090px) {
          height: 40vh;
        }
        @media (max-width: 770px) {
          height: 32vh;
        } */

        img {
          max-width: 50%; /* Reduce image width to half */
          height: auto;
          border: none;
          border-radius: 10px; /* Border radius for the image */
        }
      }
    }
  }
`

export const AppIDCSpotlightRaindropSummaryandPDFSection: React.FC = () => {
  const handleDownload = () => {
    // Path to the PDF file
    const pdfPath = '/PDF/IDC-Spotlight-on-Raindrop.pdf'

    // Create a temporary anchor element to trigger the download
    const link = document.createElement('a')
    link.href = pdfPath
    link.download = 'IDC-Spotlight-on-Raindrop.pdf' // Name of the downloaded file
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <SectionWrapper>
      <div className="content-section">
        <div className="description">
          <p>
            The procurement application market is evolving rapidly, driven by the need for better spend visibility, cost
            savings, and efficiency, especially in the face of global disruptions like COVID-19 and supply chain
            challenges. Buyers are prioritizing solutions that offer ease of use, quick time to value, and stakeholder
            engagement, with a focus on intuitive, user-friendly platforms that integrate advanced AI and machine
            learning for predictive analytics and workflow automation. The debate between full-suite solutions (e.g.,
            Ariba, Raindrop, Coupa) and point solutions (e.g., Arkestro, Basware) continues, with full-suite providers
            increasingly addressing integration and scalability concerns. Raindrop, a digital spend management platform,
            differentiates itself with modular functionalities, seamless third-party integrations, and AI-driven
            features like budget forecasting and invoice processing. Challenges include market competition and securing
            stakeholder buy-in. The market is expected to continue evolving, with providers offering transparent,
            functional, and intuitive tools gaining an edge in driving cost savings and operational efficiencies.
          </p>
        </div>
        <div className="form-and-image-container">
          <div className="form-section">
            <h3>Download the report for free!</h3>
            <button onClick={handleDownload}>Download Now</button>
          </div>
          <div className="image-container">
            <img
              src="https://storage.googleapis.com/raindroppublic/website_data/IDC-Spotlight-on-Raindrop.png"
              alt="Gartner Hype Cycle for Procurement and Sourcing Solutions 2024"
            />
          </div>
        </div>
      </div>
    </SectionWrapper>
  )
}
