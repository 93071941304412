import React from 'react'
import styled from 'styled-components'
import { ReUseHOneTag } from './ReUseHOneTag'
import { ReUsePtag } from './ReUsePtag'

const AppIDCSpotlightRaindropBannerWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  height: auto;

  .IDCSpotlightBannerBannerSection {
    height: 40vw;
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
    flex-direction: column; /* Stack content for better responsiveness */
    text-align: center;
    margin: 0 auto; /* Centers the section horizontally */
    background-image: url('https://storage.googleapis.com/raindroppublic/website_data/Gartner_banner_BG.jpg');
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }

  .IDCSpotlightBannerHeading {
    text-align: center;
    margin-top: 55px;
    font-size: 3rem;
    padding: 0px 80px 0px 80px;
    color: #f1f1f1;
    @media (max-width: 1920px) {
      font-size: 3.5rem;
    }
    @media (max-width: 1650px) {
      font-size: 3rem;
    }
    @media (max-width: 1090px) {
      font-size: 2rem;
    }
    @media (max-width: 770px) {
      font-size: 1.5rem;
    }
    @media (max-width: 480px) {
      padding-top: 15px;
      font-size: 1rem;
    }
  }
  .IDCSpotlightBannerPara {
    text-align: center;
    /* margin-top: 55px; */
    font-size: 1.2rem;
    padding: 0px 80px 0px 80px;
    color: #f1f1f1;
    /* @media (max-width: 1920px) {
      font-size: 1rem;
    }*/
    @media (min-width: 1650px) {
      font-size: 1.3rem;
    }
    /* @media (max-width: 1090px) {
      font-size: 1rem;
    }
    @media (max-width: 770px) {
      font-size: 1rem;
    }*/
    @media (max-width: 480px) {
      font-size: 0.6rem;
    }
  }
`

export const AppIDCSpotlightRaindropBanner = () => {
  return (
    <AppIDCSpotlightRaindropBannerWapper>
      <div className="IDCSpotlightBannerBannerSection">
        <div className="IDCSpotlightBannerBannerContentSection">
          <ReUseHOneTag Heading="IDC Spotlight" HeadingStyle="IDCSpotlightBannerHeading" />
          <ReUsePtag
            para="Procurement Application Providers Are Differentiating Themselves Through
Ease of Use and Time to Value"
            paraStyle="IDCSpotlightBannerPara"
          />
        </div>
      </div>
    </AppIDCSpotlightRaindropBannerWapper>
  )
}
